import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { basicColors } from 'theme/basic-colors';
import { theme } from 'theme';

export const ConditionsSection = () => {
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      component="section"
      sx={{
        mb: isMiddleScreen ? 15 : 22.5
      }}
    >
      <Stack
        direction="column"
        sx={{
          maxWidth: "1068px",
          margin: "0 auto",
          px: isMiddleScreen ? 6 : 3.5
        }}
      >
        <Typography
          variant="h2"
          sx={{
            mb: 10
          }}
        >
          Условия
        </Typography>
        <Stack direction={isMiddleScreen ? "column" : "row"} justifyContent="space-between">
          <Stack
            direction={isTabletScreen ? "column" : "row"}
            justifyContent="space-between"
            sx={{
              width: "100%",
              mr: isMiddleScreen ? 0 : 8,
              px: 8,
              py: 5,
              mb: isMiddleScreen ? 4 : 8,
              height: 160,
              borderRadius: 8,
              backgroundColor: basicColors.purple,
              [theme.breakpoints.down("md")]: {
                mr: 4,
                mb: 4,
                height: "auto"
              }
            }}
          >
            <Stack
              direction={isTabletScreen ? "column-reverse" : "column"}
              justifyContent={isTabletScreen ? "flex-end" : "space-between"}
              alignItems="flex-start"
              alignSelf="stretch"
              flex="1 0 0"
              sx={{
                maxWidth: 214,
                mr: 3,
                [theme.breakpoints.down("md")]: {
                  maxWidth: "100%",
                  mr: 0
                }
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: basicColors.white,
                  mb: isTabletScreen ? 3 : 0
                }}
              >
                Гибкий график
              </Typography>
              <Box
                sx={{
                  mb: isTabletScreen ? 3 : 0,
                  fontSize: "40px",
                  lineHeight: "40px"
                }}
              >
                ⏰
              </Box>
            </Stack>
            <Box
              sx={{
                maxWidth: isTabletScreen ? "100%" : 214
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: basicColors.white
                }}
              >
                От 20 часов в неделю, можно совмещать с учебой
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction={isTabletScreen ? "column" : "row"}
            justifyContent="space-between"
            sx={{
              width: "100%",
              px: 8,
              py: 5,
              mb: isMiddleScreen ? 4 : 8,
              height: 160,
              borderRadius: 8,
              backgroundColor: basicColors.purple,
              [theme.breakpoints.down("md")]: {
                height: "auto",
                mb: 4
              }
            }}
          >
            <Stack
              direction={isTabletScreen ? "column-reverse" : "column"}
              justifyContent={isTabletScreen ? "flex-end" : "space-between"}
              alignItems="flex-start"
              alignSelf="stretch"
              flex="1 0 0"
              sx={{
                maxWidth: 214,
                mr: 3,
                [theme.breakpoints.down("md")]: {
                  maxWidth: "100%",
                  mr: 0
                }
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: basicColors.white,
                  mb: isTabletScreen ? 3 : 0
                }}
              >
                Длительность стажировки
              </Typography>
              <Box
                sx={{
                  mb: isTabletScreen ? 3 : 0,
                  fontSize: "40px",
                  lineHeight: "40px"
                }}
              >
                📆
              </Box>
            </Stack>
            <Box
              sx={{
                maxWidth: isTabletScreen ? "100%" : 214
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: basicColors.white
                }}
              >
                От 2 месяцев до 1,5 лет. Можно брать «академ» для завершения обучения или семестра
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack direction={isMiddleScreen ? "column" : "row"} justifyContent="space-between">
          <Stack
            direction={isTabletScreen ? "column" : "row"}
            justifyContent="space-between"
            sx={{
              width: "100%",
              mr: isMiddleScreen ? 0 : 8,
              px: 8,
              py: 5,
              mb: isMiddleScreen ? 4 : 0,
              height: 220,
              borderRadius: 8,
              backgroundColor: basicColors.secondary.main,
              [theme.breakpoints.down("md")]: {
                mr: 4,
                height: "auto"
              }
            }}
          >
            <Stack
              direction={isTabletScreen ? "column-reverse" : "column"}
              justifyContent={isTabletScreen ? "flex-end" : "space-between"}
              alignItems="flex-start"
              alignSelf="stretch"
              flex="1 0 0"
              sx={{
                maxWidth: 214,
                mr: 3,
                [theme.breakpoints.down("md")]: {
                  maxWidth: "100%",
                  mr: 0
                }
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: basicColors.black,
                  mb: isTabletScreen ? 3 : 0
                }}
              >
                Формат работы
              </Typography>
              <Box
                sx={{
                  mb: isTabletScreen ? 3 : 0,
                  fontSize: "40px",
                  lineHeight: "40px"
              }}
              >
                😍
              </Box>
            </Stack>
            <Box
              sx={{
                maxWidth: isTabletScreen ? "100%" : 214
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: basicColors.black
                }}
              >
                Официальное трудоустройство, гибридный формат — возможность работать удаленно или в
                офисе (Москва, Санкт-Петербург, Барнаул, Новосибирск)
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction={isTabletScreen ? "column" : "row"}
            justifyContent="space-between"
            sx={{
              width: "100%",
              px: 8,
              py: 5,
              height: 220,
              borderRadius: 8,
              backgroundColor: basicColors.secondary.main,
              [theme.breakpoints.down("md")]: {
                height: "auto"
              }
            }}
          >
            <Stack
              direction={isTabletScreen ? "column-reverse" : "column"}
              justifyContent={isTabletScreen ? "flex-end" : "space-between"}
              alignItems="flex-start"
              alignSelf="stretch"
              flex="1 0 0"
              sx={{
                maxWidth: 214,
                mr: 3,
                [theme.breakpoints.down("md")]: {
                  maxWidth: "100%",
                  mr: 0
                }
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: basicColors.black,
                  mb: isTabletScreen ? 3 : 0
                }}
              >
                Бонусная программа
              </Typography>
              <Box
                sx={{
                  mb: isTabletScreen ? 3 : 0,
                  fontSize: "40px",
                  lineHeight: "40px"
                }}
              >
                🎁
              </Box>
            </Stack>
            <Box
              sx={{
                maxWidth: isTabletScreen ? "100%" : 214
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: basicColors.black
                }}
              >
                После прохождения испытательного срока: ДМС, оплата организации домашнего рабочего
                места или спортивных активностей, корпоративное обучение, занятие английским языком
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
